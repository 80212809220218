<template>
  <div class="d-flex align-items-center justify-content-center">
    <button v-if="row.status === 'queued'" @click.prevent="openCancel" type="button" class="btn btn-icon" v-b-tooltip.hover title="Cancel"><i class="fa fa-ban btn-icon-wrapper text-danger"></i></button>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],
  methods : {
    openCancel() {
      this.xprops.eventbus.$emit('openCancel', this.row);
    },
  }
}
</script>

<style scoped lang="scss">
  button {
    i {
      font-size: 1.6rem;
    }
  }
</style>